import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import HeaderNav from '../HeaderFotter/Header/Header.js';
import Fotter from '../HeaderFotter/Fotter/Fotter.js';
import BlurryCursor from '../Dependence/Cursor/cursor.jsx';
import FixedNav from '../HeaderFotter/FixedNav/Fixednav.js';

const sizes = {
    desktop: 1024,
    tablet: 768,
    phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
    return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
  
  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const VideoEdit = () => {
    const [isActive, setIsActive] = useState(false);
    
    return (
        <Container className='content' >

            {/* headder */}
            <HeaderNav/>

            {/* Section 1 */}
            <Section className="about-vis opacity-1" >
                <div className=''>
                    <div className='flex justify-center contacts'>
                        <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9"
                            className="uppercase text-clamp-100 m-0 ct-headline ">VIDEO EDITOR</h1>
                    </div>

                    <div className='flex justify-center uppercase blend fade z-10 leading-10 tracking-widest text-[33px]'>
                        Join Our Journey of Innovation and Excellence
                    </div>

                    <div>
                        <div id="thanh_den" style={{ width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        <div className='h-auto pl-[12%]'>
                            {/* <div id="thanh_trang" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div> */}
                        </div>
                        <div className='w-full'>
                            <p><span className='pl-5'></span>Chúng tôi đang tìm kiếm các bạn <strong>Video Editor </strong>để tham gia vào VIS GROUP của chúng
                                tôi. Với vị trí là Video Editor, bạn sẽ chịu trách nhiệm lên<strong> ý tưởng thiết kế, xây dựng
                                    kịch bản </strong>video, phối hợp với các thành viên, dựng các video theo chiến lược marketing
                                của sản phẩm. Cùng hợp tác chặt chẽ với các vị trí liên quan khác, bạn sẽ góp phần tạo ra
                                <strong>các video giới thiệu sản phẩm, giới thiệu doanh nghiệp, bản tin, các video nội bộ
                                    phục vụ truyền thông nội bộ.</strong></p>
                            <p className='text-justify'><strong> 1. Mô tả công việc: <br /></strong>
                                <div className='pl-10'>
                                    Nghiên cứu kịch bản và làm việc trực tiếp với Team để nắm rõ mục tiêu.<br />
                                    Xây dựng các clip quảng cáo, clip Viral, video giới thiệu sản phẩm, mô tả sản phẩm.<br />
                                    Sáng tạo các nội dung video mới theo trend và định hướng của tổ chức.<br />
                                    Triển khai setup bối cảnh và quay theo nội dung kịch bản.<br />
                                    Lưu trữ và lựa chọn cảnh quay hợp lí nhất.<br />
                                    Tham gia các buổi quay để nắm bắt bố cục video.<br />
                                    Chỉnh sửa video, xử lí âm thanh, chèn nhạc, hiệu ứng,...tạo ra nội dung hài hòa , cân
                                    đối.<br />
                                    Thực hiện quay, dựng video, chụp ảnh, cắt ghép, sắp xếp, xâu chuỗi các đoạn video thô
                                    theo thứ tự để tạo thành một câu chuyện hoàn chỉnh có nghĩa.<br />
                                    Chỉnh sửa video theo yêu cầu một cách hoàn chỉnh.<br />
                                    Kết hợp các bộ phận khác để hoàn thành sản phẩm đúng nhất.
                                </div>

                            </p>
                            <p className='text-justify'><strong>2. Yêu cầu cơ bản:<br /></strong>
                                <div className='pl-10'>
                                    Có kinh nghiệm làm việc trên Tiktok là một lợi thế.<br />
                                    Có khả năng sử dụng cơ bản hoặc thành thạo các phần mềm dựng Adobe Premiere,
                                    Capcut (có thể biết thêm After Effects, Photoshop, Illustrator).<br />
                                    Đam mê với lĩnh vực thiết kế, có gu thẩm mỹ tốt.<br />
                                    Tư duy sáng tạo, ham học hỏi, có tinh thần trách nhiệm cao với công việc.<br />
                                    Có thể sử dụng Tiếng Anh là một điểm mạnh.<br />
                                    Có khả năng và yêu thích công việc sáng tạo.<br />
                                    Có kỹ năng quay, lấy góc máy, ánh sáng, bố cục tốt.<br />
                                    Có kinh nghiệm quay dựng và edit video.<br />
                                    Đa dạng luân phiên background, đa dạng phong cách phù hợp.<br />
                                    Có kỹ năng làm việc nhóm tốt, hỗ trợ đồng nghiệp cùng/khác team và giao tiếp hiệu
                                    quả.<br />
                                    Có hiểu biết về lĩnh vực Thương mại điện tử.
                                </div>
                            </p>
                            <p className='text-justify'><strong>3. Quyền lợi: <br /></strong>
                                <div className='pl-10'>
                                    Lương: 6,5 - 10 triệu (dựa vào năng lực)<br />
                                    Được cấp máy tính để làm việc.<br />
                                    Thử việc 2 tháng, 85% lương chính thức.<br />
                                    Thưởng theo kết quả hoạt động kinh doanh.<br />
                                    Lương tháng 13, review performance 2 lần/ năm.<br />
                                    12 ngày phép năm; nghỉ lễ Tết theo quy định Nhà nước.<br />
                                    Du lịch trong & ngoài nước hằng năm, tham gia vào các hoạt động thú vị tại
                                    công ty: Monthly Birthday, Team Building, Christmas, Year-End Party,,...<br />
                                    Môi trường làm việc thân thiện, thẳng thắn, công bằng, năng động, sáng tạo
                                    không ngừng.<br />
                                    Thoải mái đưa ra những ý tưởng, đề xuất của bản thân vì chúng tôi luôn tin vào
                                    năng lực của mỗi cá nhân và làm việc với văn hóa “Advance on Trust”.
                                </div>
                            </p>
                            <p>🙌 Hãy ứng tuyển ngay để bắt đầu một hành trình sự nghiệp thú vị với đại gia đình VIS chúng tôi !</p>
                        </div>
                    </div>

                    <div>
                        <div className='mt-48' id="thanh_trang" style={{ minHeight: "1px", height: "1px", width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        {/* <div className='h-auto pl-20'>
                            <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
                        </div> */}
                        <div className='w-full '>
                            <p className='text-justify'><strong>Thông tin liên hệ VIS GROUP:<br /></strong>
                                <div className='pl-10'>
                                    - Địa chỉ làm việc: 15 Nguyễn Mộng Tuân, Liên Chiểu, Đà Nẵng<br />
                                    - Thời gian làm việc:<br />
                                    <div className='pl-10'>
                                        + Full-time làm việc các ngày từ thứ 2 – thứ 7 (thứ 7 làm cách tuần).<br />
                                        + Sáng: 8h00 – 12h00 Chiều: 13h30 – 17h30<br />
                                    </div>
                                    <p></p>
                                    Ứng viên vui lòng gửi CV về địa chỉ email: recruitment@visgroup.co<br />
                                    Email title <span className='text-red-400'>[VIDEO EDITOR – HỌ TÊN – SĐT]</span>
                                </div>

                            </p>
                        </div>
                    </div>
                </div>
            </Section>

            <Fotter></Fotter>

            <FixedNav />
            <BlurryCursor isActive={isActive} />
        </Container>
    );
};

export default VideoEdit;