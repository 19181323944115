import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';
// import "./4TT.css"

const Container = styled.div`
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px;
    width: 100%;
    
    // height: 80%
    `;

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

// const nput = styled.input``

const Documents = (nextPath) => {
    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setActiveIndex(currentIndex);
    }, [location, navigate, menuItems, setActiveIndex]);

    const handlePreviousClick = () => {
        const nextIndex = (activeIndex - 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    return (
        <Container>
            <div className="form-container">
                <h2 style={{ "display": "flex", "justify-content": "center" }}>XÁC NHẬN</h2>
                <p> Hãy dành thời gian để đảm bảo bạn đã hoàn thành mỗi bước trong hướng dẫn Onboarding này. Khi bạn hoàn thành, bạn đã sẵn sàng hoàn toàn! </p>
                <img style={{width: "100%", scale: "85%"}} src='/Img/welcom.png' alt='Congratulations'></img>

                <p>Bài viết dưới đây là thông tin để giúp bạn làm quen với Công ty và nhóm của bạn nhanh hơn! Ngại gì không đọc để tham khảo ! <a href="https://visgroup.larksuite.com/wiki/wikussXHA9V7a7JuS43iKlvTaEd">Thông tin thêm</a></p>
                <p>Chúc bạn có một ngày vui vẻ! ^^</p>
                <p></p>
            </div>
            <div>
                <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
                    <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
                </div>
            </div>
        </Container>
    );
};

export default Documents;