import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';
import { useForm } from 'react-hook-form';

import "./4TT.css"

const Container = styled.div`
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px;
    width: 100%;
    `;

// margin-left: 300px;
const ButtonNext = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    background-color:#1549e1bf;
    font-weight: bold;
    
    &:hover {
        background-color: #1d357dbf;
        color: white;
    }
`

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

// const nput = styled.input``

const InfoNS = (nextPath) => {
    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setActiveIndex(currentIndex);
    }, [location, navigate, menuItems, setActiveIndex]);

    const { register, handleSubmit, formState: { errors }, setValue  } = useForm();
    
    useEffect(() => {
        const savedData = localStorage.getItem('formData');
        if (savedData) {
            const formData = JSON.parse(savedData);
            console.log(formData);
            for (const [key, value] of Object.entries(formData)) {
                setValue(key, value);
            }
        }
    }, [setValue]);

    const onSubmit = data => {
        localStorage.setItem('formData', JSON.stringify(data))
        const nextIndex = (activeIndex + 1) % menuItems.length;
        setActiveIndex(nextIndex);
        navigate(menuItems[nextIndex].path);
        console.log(data);
        // sendDataToServer(formData);
    };

    const handlePreviousClick = () => {
        const nextIndex = (activeIndex - 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    return (
        <Container>
            <h2 style={{ "display": "flex", "justify-content": "center" }}>THÔNG TIN NHÂN SỰ</h2>
            <p> Điền các thông tin dưới đây để phòng nhân sự có thể nhanh chóng cập nhật thông tin của nhân viên. </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-container">

                    {/* Thông tin cá nhân */}
                    <div><strong>Thông tin cá nhân</strong> <br></br> Thông tin chi tiết</div>
                    <section className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="firstName">Tên * </label>
                                <input type="text" {...register("firstName", { required: "Tên là bắt buộc" })} />
                                {errors.firstName && <div style={{ color: "red" }}>{errors.firstName.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="lastName">Họ và tên đệm *</label>
                                <input type="text" {...register("lastName", { required: "Họ và tên đệm là bắt buộc" })} />
                                {errors.lastName && <div style={{ color: "red" }}>{errors.lastName.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label for="gender">Giới tính *</label>
                                <select name="gender"  {...register("gender", { required: "Giới tính là bắt buộc" })}>
                                    <option value="Nam">Nam</option>
                                    <option value="Nữ">Nữ</option>
                                </select>
                                {errors.gender && <div style={{ color: "red" }}>{errors.gender.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="lastName">Tình trạng hôn nhân *</label>
                                <select name="maritalStatus"  {...register("maritalStatus", { required: "Tình trạng hôn nhân là bắt buộc" })} >
                                    <option value="Chưa kết hôn"> Chưa kết hôn </option>
                                    <option value="Đã lập gia đình">Đã lập gia đình </option>
                                </select>
                                {errors.maritalStatus && <div style={{ color: "red" }}>{errors.maritalStatus.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label for="email">Email *</label>
                                <input type="email" name="email" {...register("email", { required: "Email là bắt buộc" })} />
                                {errors.email && <div style={{ color: "red" }}>{errors.email.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="phone">Số điện thoại *</label>
                                <input type="text" name="phone" {...register("phone", { required: "Số điện thoại là bắt buộc" })} />
                                {errors.phone && <div style={{ color: "red" }}>{errors.phone.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label> Ngày sinh *</label>
                                <input type="date" name="birthDate" {...register("birthDate", { required: "Ngày sinh là bắt buộc" })} />
                                {errors.birthDate && <div style={{ color: "red" }}>{errors.birthDate.message}</div>}
                            </div>
                        </div>
                    </section>

                    {/* Thông tin chung */}
                    <div><strong>Thông tin chung</strong> <br></br> Thông tin chung bổ sung</div>
                    <section className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="temporaryAddress">Địa chỉ tạm trú * </label>
                                <input type="text" name="temporaryAddress" {...register("temporaryAddress", { required: "Địa chỉ tạm trú là bắt buộc" })} />
                                {errors.temporaryAddress && <div style={{ color: "red" }}>{errors.temporaryAddress.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="permanentAddress">Địa chỉ thường trú *</label>
                                <input type="text" name="permanentAddress" {...register("permanentAddress", { required: "Địa chỉ thường trú là bắt buộc" })} />
                                {errors.permanentAddress && <div style={{ color: "red" }}>{errors.permanentAddress.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label for="placeOfBirth">Nơi sinh *</label>
                                <input type="text" name="placeOfBirth" {...register("placeOfBirth", { required: "Nơi sinh là bắt buộc" })} />
                                {errors.placeOfBirth && <div style={{ color: "red" }}>{errors.placeOfBirth.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="nationality">Quốc tịch *</label>
                                <input type="text" name="nationality" {...register("nationality", { required: "Quốc tịch là bắt buộc" })} />
                                {errors.nationality && <div style={{ color: "red" }}>{errors.nationality.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label for="idNumber">Số CCCD/CMND (ID) *</label>
                                <input type="text" name="idNumber" {...register("idNumber", { required: "Số CCCD/CMND (ID) là bắt buộc" })} />
                                {errors.idNumber && <div style={{ color: "red" }}>{errors.idNumber.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="idIssueDate">Ngày cấp CCCD/CMND *</label>
                                <input type="date" name="idIssueDate" {...register("idIssueDate", { required: "Ngày cấp CCCD/CMND là bắt buộc" })} />
                                {errors.idIssueDate && <div style={{ color: "red" }}>{errors.idIssueDate.message}</div>}
                            </div>
                        </div>
                        <div className="form-group">
                            <label> Nơi cấp CCCD/CMND *</label>
                            <input type="text" name="idIssuePlace" {...register("idIssuePlace", { required: "Nơi cấp CCCD/CMND là bắt buộc" })} />
                            {errors.idIssuePlace && <div style={{ color: "red" }}>{errors.idIssuePlace.message}</div>}
                        </div>
                        <div className="form-group">
                            <label> Facebook </label>
                            <input type="text" name="facebook" {...register("facebook")} />
                            {/* {errors.facebook && <div style={{ color: "red" }}>{errors.facebook.message}</div>} */}
                        </div>
                    </section>


                    {/* Tài khoản ngân hàng */}
                    <div><strong>Tài khoản ngân hàng</strong> <br></br> Thông tin tài khoản ngân hàng</div>
                    <section className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="bankAccountNumber"> Số tài khoản * </label>
                                <input type="text" name="bankAccountNumber" {...register("bankAccountNumber", { required: "Số tài khoản là bắt buộc" })} />
                                {errors.bankAccountNumber && <div style={{ color: "red" }}>{errors.bankAccountNumber.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="accountHolderName">Tên chủ tài khoản *</label>
                                <input type="text" name="accountHolderName" {...register("accountHolderName", { required: "Tên chủ tài khoản là bắt buộc" })} />
                                {errors.accountHolderName && <div style={{ color: "red" }}>{errors.accountHolderName.message}</div>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group">
                                <label for="bankName">Tên ngân hàng *</label>
                                <input type="text" name="bankName" {...register("bankName", { required: "Tên ngân hàng là bắt buộc" })} />
                                {errors.bankName && <div style={{ color: "red" }}>{errors.bankName.message}</div>}
                            </div>
                            <div className="form-group">
                                <label for="bankBranch">Chi nhánh ngân hàng *</label>
                                <input type="text" name="bankBranch" {...register("bankBranch", { required: "Chi nhánh ngân hàng là bắt buộc" })} />
                                {errors.bankBranch && <div style={{ color: "red" }}>{errors.bankBranch.message}</div>}
                            </div>
                        </div>
                    </section>


                    {/* Thuế và bảo hiểm */}
                    <div><strong>Thuế và bảo hiểm</strong> <br></br> Thông tin thuế và bảo hiểm</div>
                    <section className="form-section">
                        <div className="form-row">
                            <div className="form-group">
                                <label for="taxCode">Mã số thuế </label>
                                <input type="text" name="taxCode" {...register("taxCode")} />
                                {/* {errors.taxCode && <div style={{ color: "red" }}>{errors.taxCode.message}</div>} */}
                            </div>
                            <div className="form-group">
                                <label for="lastName">Mã số bảo hiểm</label>
                                <input type="text" name="insuranceNumber" {...register("insuranceNumber")} />
                                {/* {errors.insuranceNumber && <div style={{ color: "red" }}>{errors.insuranceNumber.message}</div>} */}
                            </div>
                        </div>

                    </section>
                </div>
                {/* <button type="submit">Submit</button> */}
                <div>
                    <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
                        <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
                        <ButtonNext  type="submit"  >Tiếp theo</ButtonNext>
                    </div>
                </div>
            </form>

        </Container>
    );
};

export default InfoNS;
