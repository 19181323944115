import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px
    `;

// margin-left: 300px;
const ButtonNext = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    background-color:#1549e1bf;
    font-weight: bold;
    
    &:hover {
        background-color: #1d357dbf;
        color: white;
    }
`

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

const AboutVis = (nextPath) => {
  const { activeIndex, setActiveIndex, menuItems } = useNavigation();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
    setActiveIndex(currentIndex);
  }, [location, navigate, menuItems, setActiveIndex]);

  const handleNextClick = () => {
    const nextIndex = (activeIndex + 1) % menuItems.length;
    navigate(menuItems[nextIndex].path);
    setActiveIndex(nextIndex);
  };

  const handlePreviousClick = () => {
    const nextIndex = (activeIndex - 1) % menuItems.length;
    navigate(menuItems[nextIndex].path);
    setActiveIndex(nextIndex);
  };

  return (
    <Container>
      <div>
        <h2 style={{ "display": "flex", "justify-content": "center" }}>VỀ VIS GROUP</h2>
        <p>ĐẠI GIA ĐÌNH VIS GROUP
        </p>
        <p>
          VIS GROUP được thành lập từ năm 2015, là công ty E-commerce đang trên đà phát triển mạnh mẽ trên thị trường Âu - Mỹ.
        </p>
        <p>
        Tất cả những đóng góp của bạn cho VIS GROUP sẽ được đền đáp xứng đáng, mọi giá trị đều được sẻ chia!
        </p>
        <img src='/Img/bg.jpg' alt='backgroud' style={{ width: "100%", scale: "80%" }}></img>
      </div>
      <div>
        <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
          <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
          <ButtonNext onClick={handleNextClick} >Tiếp theo</ButtonNext>
        </div>
      </div>
    </Container>
  );
};

export default AboutVis;
