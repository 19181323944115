import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { useGSAP } from '@gsap/react';

import HeaderNav from '../../HeaderFotter/Header/Header.js';
import Fotter from '../../HeaderFotter/Fotter/Fotter.js';
import BlurryCursor from '../../Dependence/Cursor/cursor.jsx';
import FixedNav from '../../HeaderFotter/FixedNav/Fixednav.js';
import { effect_gsap, effect_out, move_year, effect_img } from '../../../gsapHelper/effectGsap.js';

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
  
  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const Recruitment = () => {
  const [isActive, setIsActive] = useState(false);
  gsap.registerPlugin(useGSAP);
  const content = useRef()

  gsap.registerPlugin(ScrollTrigger)

  useEffect(() => {
    // Chắc chắn rằng window và document đều sẵn sàng
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      ScrollTrigger.refresh();
    }
  }, []);

  useEffect(() => {
    const imagesLoaded = document.querySelectorAll('img');
    const totalImages = imagesLoaded.length;
    let imagesLoadedCount = 0;

    imagesLoaded.forEach(image => {
      if (image.complete) {
        imagesLoadedCount++;
      } else {
        image.onload = () => {
          imagesLoadedCount++;
          if (imagesLoadedCount === totalImages) {
            // Khởi tạo ScrollTrigger sau khi tất cả hình ảnh đã tải xong
            ScrollTrigger.refresh();
          }
        };
      }
    });

    if (imagesLoadedCount === totalImages) {
      ScrollTrigger.refresh();
    }
  }, []);


  useGSAP(
    () => {
      const tl = gsap.timeline({ repeat: -1, yoyo: true });
      tl.to(".loLung", { y: -25, duration: 1.5, ease: "power1.inOut" })
        .to(".loLung", { y: 0, duration: 1.5, ease: "power1.inOut" });

      // gsap.from(".effect1", {
      //   opacity: 0,
      //   y: -100,
      //   duration: 5,
      //   ease: "power3.out"
      // });

      // gsap.from(".effect2", {
      //   opacity: 0,
      //   y: -200,
      //   duration: 3,
      //   ease: "power3.out"
      // });

      // Di chuyển khối
      // effect_gsap('.Introduction', 0);
      effect_out('.Introduction', -100);

      effect_gsap('.our-mission', 0);
      effect_out('.our-mission', -100);

    },
    { scope: content }
  );

  return (
    <Container className='content' ref={content}>

      {/* headder */}
      <HeaderNav></HeaderNav>

      {/* Section 1 */}
      <Section className="about-vis opacity-1" >
        <div className=''>
          <div className='flex justify-center contacts'>
            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp-100 m-0 ct-headline ">Careers at VIS Group</h1>

          </div>
          <div className='pl-5 uppercase blend fade z-10 leading-10 tracking-widest text-[33px]'>Join Our Journey of Innovation and Excellence</div>
          <div>
            <div id="thanh_den" style={{ width: "100%" }}></div>
          </div>

          <div className='flex justify-between gap-5'>
            <div className='h-auto pl-20'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <p className='text-justify'><strong className='pl-5'>At VIS Group,</strong> we thrive at the intersection of technology and creativity, delivering outstanding e-commerce solutions to a global clientele. Since our inception in 2015, we've established a strong presence in the European and American markets, setting new standards in digital commerce and marketing. Our commitment is to not only achieve business success but to enhance the professional lives of our team members through continuous growth and a supportive work environment.<br/>
              <span className='pl-5'>We are always on the lookout for talented</span> individuals who are passionate about design and innovation. If you're ready to contribute to a dynamic team and work on exciting projects, check out our Careers page for current opportunities.              </p>
            </div>
          </div>
        </div>
      </Section>


      {/* Section 2 */}
      <Section className='Introduction opacity-1'>
        <div className='md:flex'>

          <div className='md:flex flex-col gap-10'>
            <div className='uppercase text-[30px] md:text-[50px] font-bold'>Why VIS Group?</div>
            <div id='thanh_den' style={{ width: "100%" }}> </div>
            <div className='w-full relative loLung'>
              <div className='flex justify-center'>
                <div className='absolute bg-black rounded-3xl h-full w-4/12 z-[-1] loLung '></div>
              </div>


              <div className='w-full flex justify-center pb-20'>
                <div className='flex items-center'>
                  <div className='bg-white p-5 rounded-3xl shadow-2xl'>
                    <strong>Innovative Culture:</strong> We are pioneers in developing cutting-edge products and campaigns that set industry standards.<br />
                    <strong>Creative Freedom: </strong>Engage in projects that stimulate your creativity and professional growth.
                  </div>
                </div>
                <div className='flex justify-end pt-5'>
                  <img src='/Img/AboutUs/office.png' alt='' className='w-full h-auto md:w-11/12 rounded-3xl' style={{ objectFit: "cover", boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                </div>
              </div>

              <div className='w-full flex justify-center pb-20'>
                <div>
                  <img src='/Img/HomePage/firts_vis.jpg' alt='' className='w-full md:w-11/12 rounded-3xl' style={{ boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                </div>
                <div className='flex items-center'>
                  <div className='bg-white p-5 rounded-3xl shadow-2xl'>
                    <strong>Employee Growth:</strong> Dive into opportunities for professional development and personal advancement.<br />
                    <strong>Dynamic Environment: </strong>Work with a diverse team that values fresh ideas and perspectives.

                  </div>
                </div>
              </div>

              <div className='w-full flex justify-center pb-20'>
                <div className='flex items-center'>
                  <div className='bg-white p-5 rounded-3xl shadow-2xl'>
                    <strong>Rewarding Environment:</strong> Enjoy a transparent reward system that recognizes and compensates your hard work and creative contributions.<br />
                    <strong>Competitive Benefits: </strong>Enjoy a rewarding package that appreciates your hard work and dedication.
                  </div>
                </div>
                <div className='flex justify-end pt-5'>
                  <img src='/Img/HomePage/2_vis.jpg' alt='' className='w-full h-auto md:w-11/12 rounded-3xl' style={{objectFit: "cover", boxShadow: "13px 10px 9px #16231bba, -10px -10px 20px 7px #0c1f0c7a, 13px -2px 20px 11px rgb(0 0 0 / 0.25)" }}></img>
                </div>
              </div>
            </div>
          </div>

        </div>
      </Section>

      {/* Section 5 */}
      <Section className="Corporate-Social opacity-1" >
        <div className=''>
          <div className='flex justify-center contacts'>
            <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9" className="uppercase text-clamp-100 m-0 ct-headline ">recruitment positions</h1>
          </div>
          <div>
            <div id="thanh_den" style={{ width: "100%" }}></div>
          </div>

          <div className='flex justify-between gap-5 pb-5'>
            <div className='h-auto pl-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <p className='text-justify uppercase font-bold'>Designer [Closed] </p>
              <a href='/jddesign'>Detail</a>
            </div>
          </div>

          {/* <div className='flex justify-between gap-5'>
            <div className='w-full'>
              <p className='text-justify flex justify-end uppercase font-bold'>SOFTWARE DEVELOPER [closed]</p>
              <div><a href='/jddevelop' className=' flex justify-end'>Detail</a></div>
            </div>
            <div className='h-auto pr-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
          </div> */}
          <div className='flex justify-between gap-5 pb-5'>
            <div className='h-auto pl-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <span className='text-justify uppercase font-bold'>SOFTWARE DEVELOPER [closed]</span>
              <div><a href='/jddevelop'>Detail</a></div>
            </div>
          </div>


          <div className='flex justify-between gap-5  pb-5'>
            <div className='h-auto pl-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <p className='text-justify uppercase font-bold'>Video Editor [closed]</p>
              <div><a href='/videoedit'>Detail</a></div>
            </div>

          </div>


          {/* <div className='flex justify-between gap-5'>
            <div className='w-full'>
              <p className='text-justify flex justify-end uppercase font-bold'></p>
              <div><a href='/idea' className=' flex justify-end'>Detail</a></div>
            </div>
            <div className='h-auto pr-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
          </div> */}
          <div className='flex justify-between gap-5 pb-5'>
            <div className='h-auto pl-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <span className='text-justify uppercase font-bold'>Idea Creator [ <span className='text-green-600'>On-going</span> ]</span>
              <div><a href='/idea'>Detail</a></div>
            </div>
          </div>


          {/* <div className='flex justify-center'> */}
          <div className='flex justify-between gap-5'>
            <div className='h-auto pl-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
            <div className='w-full'>
              <span className='text-justify uppercase font-bold'>Customer Support [ <span className='text-green-600'>On-going</span> ]</span>
              <div><a href='/jdsupport'>Detail</a></div>
            </div>
          </div>
          {/* </div> */}

          {/* <div className='flex justify-between gap-5'>
            <div className='w-full'>
              <p className='text-justify flex justify-end uppercase font-bold'>SOFTWARE DEVELOPER [closed]</p>
              <div><a href='/contacts' className=' flex justify-end'>Detail</a></div>
            </div>
            <div className='h-auto pr-[20%]'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
          </div>
          <div className='flex justify-between gap-5'>
            <div className='w-full'>
              <p className='text-justify flex justify-end uppercase font-bold'>SOFTWARE DEVELOPER [closed]</p>
              <div><a href='/contacts' className=' flex justify-end'>Detail</a></div>
            </div>
            <div className='h-auto pr-20'>
              <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
            </div>
          </div> */}
        </div>
      </Section>

      {/* Section 7 */}
      <Section className='info-overview'>
        <div>
          <div> Phone: +84326239515 </div>
          <div> Address: 15 Nguyen Mong Tuan, Hoa Minh, Lien Chieu, Da Nang </div>
          <div> Page:<a className='no-underline' href='https://www.facebook.com/visstudio.co'> VIS Group</a> </div>
          <br />
          <div id="ct-main-icon">
            <img alt="" src="/Img/icon/amazon.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="/Img/icon/tiktok.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="/Img/icon/etsy.jfif" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="https://www.pinguinweb.de/wp-content/uploads/2022/09/woocommerce.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
            <img alt="" src="https://www.pinguinweb.de/wp-content/uploads/2022/09/oxy.png" className="ct-image fade entered lazyloaded" style={{ transform: "translate(0px, 0px)", opacity: "1" }} />
          </div>
        </div>
      </Section>

      <Fotter></Fotter>

      <FixedNav />
      <BlurryCursor isActive={isActive} />
    </Container>
  );
};

export default Recruitment;