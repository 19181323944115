import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import HeaderNav from '../HeaderFotter/Header/Header.js';
import Fotter from '../HeaderFotter/Fotter/Fotter.js';
import BlurryCursor from '../Dependence/Cursor/cursor.jsx';
import FixedNav from '../HeaderFotter/FixedNav/Fixednav.js';

const sizes = {
    desktop: 1024,
    tablet: 768,
    phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
    acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
    return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box; 
  
  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const Idea = () => {
    const [isActive, setIsActive] = useState(false);


    return (
        <Container className='content' >

            {/* headder */}
            <HeaderNav/>

            {/* Section 1 */}
            <Section className="about-vis opacity-1" >
                <div className=''>
                    <div className='flex justify-center contacts'>
                        <h1 onMouseOver={() => { setIsActive(true) }} onMouseLeave={() => { setIsActive(false) }} id="headline-4-9"
                            className="uppercase text-clamp-100 m-0 ct-headline ">IDEA</h1>
                    </div>

                    <div className='flex justify-center uppercase blend fade z-10 leading-10 tracking-widest text-[33px]'>
                        Join Our Journey of Innovation and Excellence
                    </div>

                    <div>
                        <div id="thanh_den" style={{ width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        <div className='h-auto pl-[12%]'>
                            {/* <div id="thanh_trang" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div> */}
                        </div>
                        <div className='w-full'>
                            <p className='text-justify'><strong>🌈Yêu cầu công việc: <br /></strong>
                                <div className='pl-10'>
                                    Kỹ năng nghiên cứu thị trường, nắm bắt trend tốt.<br />
                                    Kỹ năng giao tiếp tốt, nhanh nhẹn.<br />
                                    Có tư duy thẩm mỹ và khả năng sáng tạo ý tưởng tốt.<br />
                                    Năng động, nhiệt huyết với công việc.<br />
                                    Có thể sử dụng Tiếng Anh tốt.<br />
                                    Nắm rõ các mùa sale, niche, có mẫu từng bán tốt là lợi thế.<br />
                                    Có kinh nghiệm trên 6 tháng về vị trí Idea trong lĩnh vực POD là điểm mạnh.
                                </div>

                            </p>
                            <p className='text-justify'><strong>⇨ Mô tả công việc: <br /></strong>
                                <div className='pl-10'>
                                    Nghiên cứu, nắm bắt xu hướng tại thị trường Mỹ và châu  Âu.<br />
                                    Tìm kiếm và sáng tạo các ý tưởng thiết kế hay, độc đáo cho sản phẩm.<br />
                                    Sáng tạo concept cho sản phẩm trên các sàn thương mại điện tử.<br />
                                    Làm việc với designer để triển khai thiết kế sản phẩm.

                                </div>
                            </p>
                            <p className='text-justify'><strong>💪Quyền lợi: <br /></strong>
                                <div className='pl-10'>
                                    Lương thỏa thuận theo năng lực.<br />
                                    Thưởng theo doanh số, thái độ và hiệu quả công việc hàng quý (không giới hạn mức thưởng).<br />
                                    Phụ cấp ăn trưa.<br />
                                    Thưởng quý, năm, tháng 13, thưởng nóng, thưởng lễ, tết,....<br />
                                    Phụ cấp bảo hiểm theo quy định của Nhà nước.<br />
                                    Tham gia các hoạt động teambuilding hấp dẫn. Cắm trại, du lịch hằng năm.<br />
                                    Môi trường làm việc năng động, trẻ trung, thân thiện, chuyên nghiệp.
                                </div>
                            </p>
                        </div>
                    </div>

                    <div>
                        <div className='mt-48' id="thanh_trang" style={{ minHeight: "1px", height: "1px", width: "100%" }}></div>
                    </div>

                    <div className='flex justify-between gap-5'>
                        {/* <div className='h-auto pl-20'>
                            <div id="thanh_den" style={{ width: "2px", minWidth: "2px", height: "100%", margin: 0 }}></div>
                        </div> */}
                        <div className='w-full '>
                            <p className='text-justify'><strong>Thông tin liên hệ VIS GROUP:<br /></strong>
                                <div className='pl-10'>
                                    - Địa chỉ làm việc: 15 Nguyễn Mộng Tuân, Liên Chiểu, Đà Nẵng<br />
                                    - Thời gian làm việc:<br />
                                    <div className='pl-10'>
                                        + Full-time làm việc các ngày từ thứ 2 – thứ 7 (thứ 7 làm cách tuần).<br />
                                        + Sáng: 8h00 – 12h00 Chiều: 13h30 – 17h30<br />
                                    </div>
                                    <p></p>
                                    Ứng viên vui lòng gửi CV về địa chỉ email: recruitment@visgroup.co<br />
                                    Email title <span className='text-red-400'>[IDEA – HỌ TÊN – SĐT]</span>
                                </div>

                            </p>
                        </div>
                    </div>
                </div>
            </Section>

            <Fotter></Fotter>

            <FixedNav />
            <BlurryCursor isActive={isActive} />
        </Container>
    );
};

export default Idea;