import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: space-between;
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px
    `;

// margin-left: 300px;
const ButtonNext = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    background-color:#1549e1bf;
    font-weight: bold;
    
    &:hover {
        background-color: #1d357dbf;
        color: white;
    }
`

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

const Content = (nextPath) => {
    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setActiveIndex(currentIndex);
    }, [location, navigate, menuItems, setActiveIndex]);

    const handleNextClick = () => {
        const nextIndex = (activeIndex + 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    const handlePreviousClick = () => {
        const nextIndex = (activeIndex - 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    return (
        <Container>
            <div className='head-content'>
                <h2 style={{ "display": "flex", "justify-content": "center" }}>THƯ NGỎ</h2>

                <div className='body-content'>
                    <p>Chào các bạn,</p>
                    <p>
                        Đâu đó tôi đã đọc được rằng - [..] Phía trước bạn có thể là một bầu trời,
                        một con đường hay đơn giản chỉ là một lối đi, cứ tự tin bước
                        tới với đam mê và những khát khao lay động đến tận cùng của tuổi trẻ, và chúng tôi tin bạn sẽ làm được những điều kỳ diệu...
                    </p>
                    <p>
                        Với đội ngũ có kinh nghiệm phát triển nhiều sản phẩm online hàng đầu và triển khai các chiến dịch Marketing online từ năm 2015, VIS tin tưởng rằng sẽ đảm bảo đem đến những kết quả tốt nhất, đặc biệt là cuộc sống vật chất, tinh thần phong phú của nhân viên Công ty cũng được ưu tiên hàng đầu.
                    </p>
                    <p>
                        Chào mừng bạn đến với một tập thể năng động, sáng tạo và nhiệt huyết. Hãy hòa mình vào VIS để cảm nhận hết nhiệt huyết của tuổi trẻ và niềm tin nơi chân lý. Chúng tôi vui mừng chào đón bạn và tin rằng cùng với sự hợp tác của bạn, VIS sẽ tiến nhanh hơn trên con đường chinh phục thử thách và hướng đến đỉnh cao của sự thành công.
                    </p>
                    <p>
                        Dù bạn là người mới đến hay đã có thâm niên, hãy cùng đọc, cùng cảm nhận và cùng nhau tạo dựng một môi trường làm việc thân thiện, hòa đồng, hiệu quả và chuyên nghiệp trong đại gia đình VIS.
                    </p>
                </div>
            </div>
            <div className='footer-content'>
                <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
                    <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
                    <ButtonNext onClick={handleNextClick} >Tiếp theo</ButtonNext>
                </div>
            </div>
        </Container>
    );
};

export default Content;
