import React, { useRef } from "react";
import styled from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-top: 30px;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;
`;

const Fotter = () => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();

  return (
    <Container className="content" ref={content}>
      <Section className="ct-section">
        <footer className="w-full opacity-100 py-8 text-[#cccccc]">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Logo */}
            <div className="mb-4 xl:w-3/12">
              <img
                src="/Img/logo_new.png"
                alt="Logo"
                className="h-[50px] w-auto"
              />
            </div>

            {/* Links */}
            <div
              className="flex mb-4 items-center gap-5
            flex-col md:flex-row"
            >
              <div>
                <p>
                  <a
                    href="mailto:hello@andreamontini.me"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" hover:underline"
                  >
                    Contact
                  </a>
                </p>
              </div>
              <div>
                <p>
                  <a
                    href="https://www.framer.com/legal/privacy-statement/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" hover:underline"
                  >
                    Privacy & Cookie Policy
                  </a>
                </p>
              </div>
              <div>
                <p className="">
                  <a
                    href="https://www.framer.com/legal/terms-of-service/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=" hover:underline"
                  >
                    Terms & Conditions
                  </a>
                </p>
              </div>
            </div>

            {/* Copyright */}
            <div className="xl:w-3/12">
              <p className="text-right pr-5 text-gray-400">
                @Copyright <span className="text-yellow-400">2000</span>
              </p>
            </div>
          </div>
        </footer>
      </Section>
    </Container>
  );
};

export default Fotter;
