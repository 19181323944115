import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";

import HeaderNav from "../../HeaderFotter/Header/Header.js";
import Fotter from "../../HeaderFotter/Fotter/Fotter.js";
import BlurryCursor from "../../Dependence/Cursor/cursor.jsx";
import FixedNav from "../../HeaderFotter/FixedNav/Fixednav.js";
import axiosInstance from "../../../axiosInstance.js";
import { ColorRing } from "react-loader-spinner";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-bottom: 100px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const DesignTest = ({ token }) => {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const fetchProtectedData = async () => {
      try {
        const response = await axiosInstance.get("/auth/protected", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.user);
        setMessage(response.data.message);
      } catch (error) {
        setMessage("You are not authorized to view this page");
        localStorage.removeItem("token");
        navigate("/login");
        // window.location.href = '/login'; // Redirect to login page
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchProtectedData();
    }
  }, [token]);

  return (
    <Container className="content">
      {/* headder */}
      <HeaderNav></HeaderNav>

      {/* Section 1 */}
      <Section
        className="our-teams opacity-1 bg-mask"
        // style={{ paddingTop: "100px" }}
      >
        <p className="text-center uppercase font-bold text-clamp-50">
          Desginer Test
        </p>
        <p className="text-center">* Note :</p>
        <div className="text-center italic text-[16px]">
          <div> Làm bài bằng phần mềm Adobe Photoshop. </div>
          <div> Thời gian làm bài trong vòng 3 tiếng, xong sớm có thể nộp trước.</div>
          <div> Nộp bài 1 file định dạng jpg or png + 1 file psd.</div>
          <div> Tạo forder ở desktop đặt Tên mình để lưu trữ bài.</div>
        </div>

        <div>
          <div className="pl-5">
            <p className="text-center uppercase font-bold text-clamp-50">
              Bài 1
            </p>
            <div className="flex justify-center">
              <p>
                <strong>Clone mẫu thiết kế bên dưới theo yêu cầu: </strong>
                <div className="pl-5">
                  - Không sử dụng cắt ghép của hình gốc <br />
                  - Thiết kế lại tất cả chi tiết để làm một hình mới giống 100%
                  hình gốc
                  <br />
                  - File chất lượng cao
                  <br />- Size 2000pixel x 2000pixel 200Dpi
                </div>
              </p>
            </div>
            <div className="flex justify-center">
              <img src="/Img/Challenge/Picture1.png" alt="" />
            </div>
          </div>

          <div className="pl-5">
            <p className="text-center uppercase font-bold text-clamp-50">
              Bài 2
            </p>
            <div className="flex justify-center">
              <p>
                <strong>
                  Thiết kế theo một phong cách mới với file mẫu bên dưới theo
                  yêu cầu :
                </strong>
                <div className="pl-5">
                  - Giữ nội dung và ý nghĩa của thiết kế. <br />
                  - Có thể thay đổi bố cục, màu chữ, màu sắc, font ảnh tùy ý.
                  <br />- File chất lượng cao. - Size 2400px x 3200px 200DPI
                </div>
              </p>
            </div>
            <div className="flex justify-center">
              <img
                className="w-8/12"
                src="/Img/Challenge/Picture2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </Section>

      <Fotter></Fotter>

      <FixedNav />
      <BlurryCursor />

      {/* <ToastContainer /> */}
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgb(75 81 99 / 80%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <ColorRing
            height={70}
            width={70}
            color="#4fa94d"
            ariaLabel="loading-indicator"
          />
        </div>
      )}
    </Container>
  );
};

export default DesignTest;
