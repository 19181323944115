import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { useNavigation } from '../../context/NavigationContext';

import { Document, Page } from 'react-pdf';

import { pdfjs } from 'react-pdf';
// 'pdfjs-dist/build/pdf.worker.min.js',
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//     `${process.env.PUBLIC_URL}/pdf.worker.min.js`,
//     import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = process.env.PUBLIC_URL + "/pdf.worker.min.js" 

// import "./4TT.css"

const Container = styled.div`
    padding-top: 60px;  // Adjust according to the height of your header
    margin: 0px 70px;
    font-size: 18px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // height: 80%
    `;

// margin-left: 300px;
const ButtonNext = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    background-color:#1549e1bf;
    font-weight: bold;
    
    &:hover {
        background-color: #1d357dbf;
        color: white;
    }
`

const ButtonPrevious = styled.button`
    width: 150px;
    padding: 15px 20px;
    border-radius: 6px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    border: none;
    font-weight: bold;

    &:hover {
        background-color: #bac1d5bf;
        color: white;
    }
`

// const nput = styled.input``

const SoTay = (nextPath) => {
    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const currentIndex = menuItems.findIndex(item => item.path === location.pathname);
        setActiveIndex(currentIndex);
    }, [location, navigate, menuItems, setActiveIndex]);

    const handleNextClick = () => {
        const nextIndex = (activeIndex + 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };

    const handlePreviousClick = () => {
        const nextIndex = (activeIndex - 1) % menuItems.length;
        navigate(menuItems[nextIndex].path);
        setActiveIndex(nextIndex);
    };


    const [numPages, setNumPages] = useState();
    // const [pageNumber, setPageNumber] = useState(1);
  
    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    return (
        <Container>
            <div className="form-container">
                <h2 style={{ "display": "flex", "justify-content": "center" }}>SỔ TAY ONBOARDING</h2>
                <p> Bạn có thể đọc các tài liệu sau để có thể nắm được các thông tin tổng quan trong quá trình làm việc tại VIS Group. </p>
                <div className="pdf-div" >
                    {/* <p>
                        Page {pageNumber} of {numPages}
                    </p> */}

                    <Document file='/Img/Quytrinh.pdf' onLoadSuccess={onDocumentLoadSuccess} style={{ width: "100%" }}>
                        {Array.apply(null, Array(numPages))
                            .map((x, i) => i + 1)
                            .map((page) => {
                                return (
                                    <Page
                                        pageNumber={page}
                                        renderTextLayer={false}
                                        renderAnnotationLayer={false}
                                    />
                                );
                            })}
                    </Document>
                    {/* <iframe title='pdf' src="http://visgroup.co/Img/Quydinh.pdf" width="100%" height="500px">
                        This browser does not support PDFs. Please download the PDF to view it: <a href="http://visgroup.co/Img/info.pdf">Download PDF</a>.
                    </iframe> */}

                </div>

            </div>
            <div>
                <div style={{ display: 'flex', "justify-content": "space-between", "padding-bottom": "50px" }}>
                    <ButtonPrevious onClick={handlePreviousClick} >Bước trước</ButtonPrevious>
                    <ButtonNext onClick={handleNextClick} >Tiếp theo</ButtonNext>
                </div>
            </div>
        </Container>
    );
};

export default SoTay;
