import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useNavigation } from '../../../context/NavigationContext';
import "./sidebarcss.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faBullseye } from '@fortawesome/free-solid-svg-icons'

const Nav = styled.nav`
    padding: 20px;
    height: 100%;
    `;

const Title = styled.h1`
    display: flex;
    justify-content: center;
    `;

const Menu = styled.ul`
    list-style: none;
    padding: 50px 0px;
    text-decoration: none;
    color: black;
    font-size: 18px;
    `;

// css custom
const StyledLink = styled(Link)`
    pointer-events: none;
    text-decoration: none;
    color: ${props => props.isActive ? 'white' : 'black'};
    background-color: ${props => props.isActive ? '#1da1f2' : 'white'};
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    box-shadow: ${props => props.isActive ? '20px 4px 5px 0px rgb(27 16 197 / 20%), 0 6px 20px 0 #3085d6;' :
        "20px 4px 5px 0px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);"}
    margin: 10px;
    font-weight: bold;
    width: 200px;

    &:hover {
        background-color: #938b8b;
        color: white;
    }
    `;

function Sidebar() {

    const { activeIndex, setActiveIndex, menuItems } = useNavigation();
    return (
        <Nav id='nav_mobile'>
            <Title><img id='img_sidebar' src='/Img/logo.png' alt='Logo' style={{ width: "30%" }} /></Title>
            <Menu id='pc'>
                {menuItems.map((item, index) => (
                    <div style={{ display: "flex", "justify-content": "center" }}>
                        <StyledLink
                            key={item.label}
                            to={item.path}
                            onClick={() => setActiveIndex(index)}
                            isActive={index <= activeIndex}
                        >
                            {item.label}
                        </StyledLink>
                    </div>
                ))}
            </Menu>
            <Menu id='mobile'>
                {menuItems.map((item, index) => (
                    <div style={{ display: "flex", "justify-content": "center" }}>
                        <StyledLink id='link'
                            key={item.label}
                            to={item.path}
                            onClick={() => setActiveIndex(index)}
                            isActive={index <= activeIndex}
                        >
                            {/* Kiểm tra xem index < active và đổi icon */}
                            <FontAwesomeIcon icon={index <= activeIndex ? faCircleCheck : faBullseye} />
                        </StyledLink>
                    </div>
                ))}
            </Menu>
        </Nav>
    );
}

export default Sidebar;
