import React, { useRef } from "react";
import styled, { css } from "styled-components";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import Slider from "./Compunents/Slider.js";

const sizes = {
  desktop: 1024,
  tablet: 768,
  phone: 576,
};

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5;
  font-size: 20px;
  font-weight: 300;
  color: #111;
`;

const Section = styled.section`
  padding: 0 50px;
  padding-top: 150px;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* Đảm bảo padding không thay đổi kích thước của div */
  box-sizing: border-box;

  ${media.desktop`
    padding: 50px 30px; // ví dụ thay đổi padding trên desktop nhỏ
  `}
  ${media.tablet`
    padding: 20px 20px; // thay đổi padding trên tablet
  `}
  ${media.phone`
    padding: 10px 10px; // thay đổi padding trên điện thoại
    padding-bottom: 100px; // giảm padding bottom trên điện thoại
  `}
`;

const SlideImg = () => {
  gsap.registerPlugin(useGSAP);
  const content = useRef();

  const year_2022 = [
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/11.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/13.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/21.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/22.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/31.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/34.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/41.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/42.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/43.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/44.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/45.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/46.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/64.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/71.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/81.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/82.jpg",
    // "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/25.jpg",
    // "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/32.jpg",
    // "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2022/33.jpg",
  ];
  const year_2023 = [
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/1.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/2.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/3.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/4.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/5.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/6.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/7.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/8.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/9.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/10.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2023/11.JPG",
  ];
  const year_2024 = [
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/1.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/2.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/3.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/4.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/5.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/6.jpg",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/7.JPG",
    "https://visgroup-web.s3.ap-southeast-1.amazonaws.com/activitive-2024/8.JPG",
  ];

  const nextSlide = () => {
    let lists = document.querySelectorAll(".item");
    document.getElementById("slide").appendChild(lists[0]);
  };

  const prevSlide = () => {
    let lists = document.querySelectorAll(".item");
    document.getElementById("slide").prepend(lists[lists.length - 1]);
  };

  const SlideItem = ({ background, title, description }) => (
    <div className="item" style={{ backgroundImage: `url(${background})` }}>
      <div className="content">
        <div className="name">{title}</div>
        <div className="des">{description}</div>
        <button onClick={() => alert("Redirecting to more info...")}>
          See more
        </button>
      </div>
    </div>
  );

  return (
    <Container className="content" ref={content}>
      <Section className="opacity-1">
        
        <div className="text-center mb-8 font-[500]">
          <div class="flex items-center justify-center gap-2 effect31">
            <img
              src="/Img/icon/icon.png"
              className="w-[22px] h-[22px]"
              alt=""
            />
            <h1 className="text-yellow-400 text-lg ml-2">Our Culture & Activitive</h1>
          </div>
          <div className="flex justify-center effect32">
            <span
              className=" text-transparent bg-clip-text w-[630px] text-center
                text-[30px] md:text-[36px]"
              style={{
                backgroundImage:
                  "linear-gradient(45deg, rgb(255, 255, 247) 8.55094%, rgb(114, 114, 103) 107.012%)",
              }}
            >
              Not only is it a workplace, VisGroup is also a common home for all members to live together and share memorable moments.
            </span>
          </div>
        </div>

        <div className="ct-main">
          <div className="ct-div-block">

            {/* ab */}
            <div className="w-full">
              <div style={{ transform: "translate(0px, 0px)", opacity: "1" }}>
                {/* <p>Our warriors are always ready to participate in any project with your business.</p> */}
                <div className="md:flex flex-col justify-center text-center pt-[50px] pb-[50px]">
                  <div className="md:flex gap-10 pb-10 md:pb-20">
                    {/* Logo */}
                    <div data-num="1" className="relative md:w-3/6">
                      <div className="relative flex justify-center">
                        <img
                          className="w-auto h-[350px]"
                          alt=""
                          src="/Img/logo.png"
                        />
                      </div>
                    </div>

                    {/* 2022 */}
                    <div data-num="2" className="relative md:w-3/6">
                      <div className="relative flex justify-center">
                        <Slider slides={year_2022} height={"390px"} />
                      </div>
                      <div className="absolute z-[2] flex justify-center w-full top-[75%]">
                        <div className="text-[#ccff0d] position-main w-10/12 bg-[#000] px-10 py-5 rounded-md opacity-70 uppercase font-bold">
                          {" "}
                          2022
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* thêm class này vào img-gallery-container */}
                  <div className="md:flex gap-10">
                    {/* 2023 */}
                    <div data-num="1" className="relative md:w-3/6  mb-10">
                      <div className="relative flex justify-center">
                        <Slider slides={year_2023} height={"390px"} />
                      </div>
                      <div className="absolute z-[2] flex justify-center w-full top-[75%]">
                        <div className="text-[#ccff0d] position-main w-10/12 bg-[#000] px-10 py-5 rounded-md opacity-70 uppercase font-bold">
                          {" "}
                          2023
                        </div>
                      </div>
                    </div>

                    {/* 2024 */}
                    <div data-num="2" className="relative md:w-3/6 mb-10">
                      <div className="relative flex justify-center">
                        <Slider
                          slides={year_2024}
                          height={"390px"}
                          className="img-gal"
                        />
                      </div>
                      <div className="absolute z-[2] flex justify-center w-full top-[75%]">
                        <div className="text-[#ccff0d] position-main w-10/12 bg-[#000] px-10 py-5 rounded-md opacity-70 uppercase font-bold">
                          {" "}
                          2024
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </Container>
  );
};

export default SlideImg;
