import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Home from "./components/Page/Home/Home.js";
import HomeNew from "./components/Page/HomeNew/Home.js";
import Sidebar from "./components/HeaderFotter/SideBar/Sidebar";
import Onboard from "./components/onboarding/1Wca";
import Content from "./components/onboarding/2Thungo";
import AboutVis from "./components/onboarding/3AboutVis";
import InfoNS from "./components/onboarding/4TT";
import Background from "./components/onboarding/5Background";
import Cmnd from "./components/onboarding/6CMND";
import SoTay from "./components/onboarding/7STOnboarding";
import Documents from "./components/onboarding/8Documents";
import Confirmation from "./components/onboarding/9Confirmation.js";
import PageAboutUs from "./components/Page/PageAboutUs/AboutUsPage.js";
import ContactsPage from "./components/Page/Contacts/ContactsPage.js";
import Recruitment from "./components/Page/Recruitment/Recruitment.js";
import OurActivities from "./components/Page/OurActivities/OurActivities.js";

import Profile from "./components/Page/Profile/Profile.js";
import ChangePassword from "./components/Page/Profile/ChangePassword.js";
import Challenge from "./components/Page/Challenge/Challenge.js";
import MiddleLink from "./components/Page/Challenge/move.js";
import DesignTest from "./components/Page/Challenge/DesignTest.js";
import IdeaTest from "./components/Page/Challenge/IdeaTest.js";

import JdDesign from "./components/PageRecruiments/jdDesign.js";
import JdDevelop from "./components/PageRecruiments/JdDevelop.js";
import VideoEdit from "./components/PageRecruiments/VideoEdit.js";
import JdSupport from "./components/PageRecruiments/JdSupport.js";
import Idea from "./components/PageRecruiments/Idea.js";

import NotFound from "./components/Page/NotFound404/NotFound.js";
import { Helmet } from "react-helmet";

import { NavigationProvider } from "./context/NavigationContext.js";

// function useDocumentHead({ title, description, hideSidebar }) {
//   useEffect(() => {
//     if (hideSidebar) {
//       document.title = title;
//       document.querySelector('meta[name="description"]').setAttribute('content', description);
//     }
//   }, [title, description, hideSidebar]);
// }

function App() {
  return (
    <NavigationProvider>
      <Router>
        <AppContent />
      </Router>
    </NavigationProvider>
  );
}

function AppContent() {
  const location = useLocation();
  // const hideSidebarPaths = ['/', '/abouts', '/contacts', '/abouts/', '/contacts/', '/recruitments', '/recruitments/'];
  const sidebarPaths = [
    "/onboarding",
    "/tn",
    "/ab",
    "/info",
    "/background",
    "/cmnd",
    "/sotay",
    "/documents",
    "/confirmation",
  ];

  // Kiểm tra xem pathname hiện tại có nằm trong mảng các đường dẫn cần ẩn sidebar không
  const sidebar = sidebarPaths.includes(location.pathname);

  function getHeadData(pathname) {
    if (sidebarPaths.includes(pathname)) {
      return {
        title: "Vis Onboarding",
        description:
          "Welcome abording! Mong rằng cùng với sự phát triển của VIS Group, chúng ta sẽ có nhiều cơ hội thể hiện chuyên môn cũng như phát huy tốt nhất khả năng làm việc của mình và ngày càng thành công hơn trong sự nghiệp",
      };
    }

    switch (pathname) {
      case "/":
        return {
          title: "Home - Discover Vis Group: Your Growth & Innovation Partner",
          description:
            "Welcome to Vis Group, your development partner!  Hopefully, along with the development of VIS Group, we will have many opportunities to show our expertise as well as promote our working abilities and become more and more successful in our careers.",
        };
      case "/abouts":
      case "/abouts/":
        return {
          title: "About Us - Learn more about Vis Group and our mission.",
          description: "Learn more about Vis Group and our mission.",
        };
      case "/contacts":
      case "/contacts/":
        return {
          title: "Contacts - Contact Vis Group for more information.",
          description: "Contact Vis Group for more information.",
        };
      case "/recruitments":
      case "/recruitments/":
        return {
          title: "Recruitments - Join our team at Vis Group!",
          description:
            "Join our team at Vis Group! Contact us for more information.",
        };
      default:
        return { title: "Job Description", description: "Job Description" };
    }
  }

  const { title, description } = getHeadData(location.pathname);

  // useDocumentHead({
  //   title: 'Vis Group',
  //   description: 'Welcome to VisGroup!',
  //   hideSidebar
  // });
  const [token, setToken] = useState("");
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  return (
    <div className={`app ${sidebar ? "" : "hide-sidebar"}`}>
      <div className="app-body">
        {sidebar && (
          <div className="side-bar">
            <Sidebar />
          </div>
        )}
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta
            name="keywords"
            content="VisGroup, business, development, partnership, vis group, VIS GROUP, vis studio"
          />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta
            property="og:image"
            content="https://visgroup.co/Img/HomePage/firts_vis.jpg"
          />
        </Helmet>
        <div className="content-area">
          <div className='align-item font-["HN_Helvetica"]'>
            {/* Cái này để setoken */}
            
            <Routes>
              <Route
                path="/challenge"
                element={
                  !token ? (
                    <Challenge setToken={setToken} />
                  ) : (
                    <MiddleLink token={token} />
                  )
                }
              />
              <Route
                path="/designtest"
                element={
                  !token ? (
                    <Challenge setToken={setToken} />
                  ) : (
                    <DesignTest token={token} />
                  )
                }
              />

              <Route
                path="/ideatest"
                element={
                  !token ? (
                    <Challenge setToken={setToken} />
                  ) : (
                    <IdeaTest token={token} />
                  )
                }
              />
              <Route
                path="/profile"
                element={
                  !token ? (
                    <Challenge setToken={setToken} />
                  ) : (
                    <Profile token={token} />
                  )
                }
              />

              <Route
                path="/password"
                element={
                  !token ? (
                    <Challenge setToken={setToken} />
                  ) : (
                    <ChangePassword token={token} />
                  )
                }
              />
              
              <Route path="/login" element= {<Challenge setToken={setToken}/>} />

              <Route path="/" element={<HomeNew />} />
              {/* <Route path="/homenew" element={<Home />} /> */}
              <Route path="/abouts" element={<PageAboutUs />} />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/recruitments" element={<Recruitment />} />
              <Route path="/ouractivities" element={<OurActivities />} />

              <Route path="/jddesign" element={<JdDesign />} />
              <Route path="/jddevelop" element={<JdDevelop />} />
              <Route path="/videoedit" element={<VideoEdit />} />
              <Route path="/idea" element={<Idea />} />
              <Route path="/jdsupport" element={<JdSupport />} />

              <Route path="/onboarding" element={<Onboard />} />
              <Route path="/tn" element={<Content />} />
              <Route path="/ab" element={<AboutVis />} />
              <Route path="/info" element={<InfoNS />} />
              <Route path="/background" element={<Background />} />
              <Route path="/cmnd" element={<Cmnd />} />
              <Route path="/sotay" element={<SoTay />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/confirmation" element={<Confirmation />} />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
